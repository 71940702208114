import ContentHero from "@components/ContentHero"
import useReviews from "@hooks/use-reviews"
import { Button, makeStyles } from "@material-ui/core"
import clsx from "clsx"
import { FormattedMessage } from "gatsby-plugin-intl"
import React, { FC, useState } from "react"
import { num } from "tiinvo"

export interface ReviewsProps {}

const usecss = makeStyles((theme) => ({
  container: {
    display: "grid",
    gap: "24px",
    margin: "0 auto",
    maxWidth: 1024,
    paddingBottom: theme.spacing(6),
    paddingTop: theme.spacing(6),
    paddingLeft: "24px",
    paddingRight: "24px",
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "1fr 1fr",
    },
    [theme.breakpoints.up("md")]: {
      gap: "40px",
      paddingBottom: theme.spacing(8),
      paddingTop: theme.spacing(8),
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: theme.spacing(4),
  },
  review: {
    display: "flex",
    flexDirection: "column",
    padding: "50px 40px 30px",
    backgroundImage: "url(/images/quote-icon.svg)",
    backgroundSize: "94px 89px",
    backgroundPosition: "30px 30px",
    backgroundRepeat: "no-repeat",
    backgroundColor: "white",
    borderRadius: 20,
    boxShadow: "0 15px 30px 0 rgba(1,1,13, 0.1)",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
  },
  author: {
    textAlign: "right",
    fontSize: "1.2rem",
  },
  message: {
    fontSize: "1.1rem",
    marginBottom: 16,
  },
}))

const add10 = num.uadd(10)

const Reviews: FC<ReviewsProps> = ({}) => {
  const classes = usecss()
  const [limit, setlimit] = useState(20)
  const increment = () => setlimit(add10)
  const { reviews, canloadmore } = useReviews(limit)

  return (
    <>
      <ContentHero
        title={
          <FormattedMessage defaultMessage="Reviews" id="pages.reviews.title" />
        }
        description={
          <FormattedMessage
            defaultMessage="Totenpass reviews from other satisfied customers."
            id="pages.reviews.description"
          />
        }
      />
      <section className={classes.container}>
        {reviews.map((review, index) => (
          <article className={classes.review} key={index}>
            <p className={classes.message}>{review.message}</p>
            <span className={classes.author}>
              <strong>- {review.author}</strong>
            </span>
          </article>
        ))}
      </section>
      {canloadmore && (
        <section className={classes.buttonContainer}>
          <Button color="primary" variant="contained" onClick={increment}>
            <FormattedMessage
              defaultMessage="Load more"
              id="pages.reviews.loadmore"
            />
          </Button>
        </section>
      )}
    </>
  )
}

export default Reviews
